import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { book } from '@bblabs/styles';
import SendingAddresses from './SendingAddresses';
import AddressInput from './AddressInput';
import Store from './Store';

const Client = ({ className }) => {
  const handleEmailVerifyClick = async value => {
    const results = await Store.sendVerification(value);
    if (!results.ok) {
      /* eslint no-console: ["warn", { allow: ["error"] }] */
      console.error(await results.text());
    }
    Store.fetchAddresses();
  };

  return (
    <div className={className}>
      <SendingAddresses />
      <div className="addressInputs">
        <AddressInput
          className="addressInput"
          placeholder="Add &amp; Verify New Email Address"
          onButtonClick={handleEmailVerifyClick}
        />
      </div>
    </div>
  );
};

Client.propTypes = {
  className: PropTypes.string,
};

Client.defaultProps = {
  className: '',
};

export default styled(Client)`
  ${book()}
  max-width: 100%;

  .addressInputs {
    padding-top: 2em;
  }

  @media (min-width: 730px) {
    .addressInputs {
      width: 50%;
    }
  }
`;
