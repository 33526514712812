import queryString from 'query-string';
import { EnvironmentHelper } from '@bblabs/classes';

const { observable } = require('mobx');

class Store {
  @observable token = null;

  @observable addresses = [];

  initialize(environment, audience, token) {
    this.environment = environment;
    this.audience = audience;
    this.token = token;
    this.clientId = queryString.parse(window.location.search).id;

    this.fetchAddresses();
  }

  ensureInitialized() {
    if (!this.environment || !this.token || !this.audience) {
      /* eslint no-console: ["warn", { allow: ["error"] }] */
      console.error('Store cannot make call until it has been initialized.');
    }
  }

  async fetchAddresses() {
    this.ensureInitialized();
    const url = this.getBaseUrl();
    const options = {
      method: 'GET',
      headers: this.getHeaders(),
    };
    const result = await fetch(url, options);
    this.addresses = await result.json();
  }

  getBaseUrl() {
    // Admins will have access to a specific client ID to use when working with specific clients.
    if (this.audience === 'admin') {
      return `${EnvironmentHelper.getApiUrl()}clients/sending_addresses/${this.clientId}`;
    }
    // Clients will not define a client id as it's available in the session info.
    return `${EnvironmentHelper.getApiUrl()}clients/sending_addresses`;
  }

  getHeaders() {
    return new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.token}`,
    });
  }

  manuallyVerify(emailAddress) {
    this.ensureInitialized();
    const url = `${this.getBaseUrl()}`;
    const options = {
      method: 'POST',
      headers: this.getHeaders(),
      body: JSON.stringify({ emailAddress }),
    };
    return fetch(url, options);
  }

  resendVerification(emailAddress) {
    this.ensureInitialized();
    const url =
      this.audience === 'admin'
        ? `${this.getBaseUrl()}/resendEmail`
        : `${this.getBaseUrl()}/client/resendEmail`;

    const options = {
      method: 'POST',
      headers: this.getHeaders(),
      body: JSON.stringify({ verificationId: emailAddress.id }),
    };
    return fetch(url, options);
  }

  remove(addressToRemove) {
    this.ensureInitialized();
    this.addresses = this.addresses.filter(address => address !== addressToRemove);

    const url = `${this.getBaseUrl()}/${addressToRemove.id}`;
    const options = {
      method: 'DELETE',
      headers: this.getHeaders(),
    };
    return fetch(url, options);
  }

  sendVerification(emailAddress) {
    this.ensureInitialized();
    const url = `${this.getBaseUrl()}`;
    const options = {
      method: 'POST',
      headers: this.getHeaders(),
      body: JSON.stringify({
        emailAddress,
        requireVerification: true,
      }),
    };
    return fetch(url, options);
  }
}

const singleton = new Store();
export default singleton;
