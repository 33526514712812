import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { colors } from '@bblabs/styles';
import { ClickableElement } from '@bblabs/atoms';
import Store from './Store';

const ResendVerificationButton = ({ address, className }) => {
  // Update resend button label after click and send
  const [resendLabel, setResendLabel] = useState('Resend Verification');
  // Debounce resend requests.
  const [sent, setSent] = useState(false);

  /**
   * On button click, resend the verification email while updating sending status in the button itself.
   */
  const resendVerificationEmail = async () => {
    if (!sent) {
      setSent(true);
      setResendLabel('Sending...');
      await Store.resendVerification(address);
      setResendLabel('Verification Sent');
    }
  };

  return (
    <ClickableElement
      className={`${className} ${sent ? 'sent' : ''}`}
      onClick={resendVerificationEmail}
    >
      {resendLabel}
    </ClickableElement>
  );
};

ResendVerificationButton.propTypes = {
  address: PropTypes.object.isRequired,
  className: PropTypes.string,
};

ResendVerificationButton.defaultProps = {
  className: '',
};

export default styled(ResendVerificationButton)`
  color: ${colors.taosSky};
  text-decoration: underline;

  &:hover {
    color: ${colors.taosSkyDark};
  }

  &.sent {
    color: ${colors.boulder};
    text-decoration: none;
  }

  &:focus {
    outline-color: ${colors.silverSandAlt};
  }
`;
