import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { book } from '@bblabs/styles';
import { Button } from '@bblabs/atoms';
import FancyModal from './Modal/FancyModal';
import AdminModalContents from './AdminModalContents';

const Admin = ({ className }) => {
  const [showModal, setShowModal] = useState(false);
  const onButtonClick = () => {
    setShowModal(true);
  };
  const handleModalClickout = () => {
    setShowModal(false);
  };
  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <div className={className}>
      <Button label="Manage Sending Addresses" color="taos-sky" rectangle onClick={onButtonClick} />
      <FancyModal show={showModal} onClickout={handleModalClickout} onClose={handleModalClose}>
        <AdminModalContents />
      </FancyModal>
    </div>
  );
};

Admin.propTypes = {
  className: PropTypes.string,
};

Admin.defaultProps = {
  className: '',
};

export default styled(Admin)`
  ${book()}
  padding: 0.5rem;
`;
