import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { colors } from '@bblabs/styles';
import { ClickableElement, InputField } from '@bblabs/atoms';
import { AddCircle } from '@bblabs/svgs';
import { inputDictionary } from '@bblabs/constants';

const AddressInput = ({ placeholder, onButtonClick, className }) => {
  const [inputValue, setInputValue] = useState('');

  const onInputValueChange = (name, value) => {
    setInputValue(value);
  };

  const handleButtonClick = () => {
    if (inputDictionary.email.test(inputValue)) {
      onButtonClick(inputValue);
      setInputValue('');
    }
  };

  return (
    <div className={className}>
      <InputField
        type="email"
        className="inputField"
        name="inputField"
        value={inputValue}
        placeholder={placeholder}
        onChange={onInputValueChange}
        autoComplete="off"
      />
      <ClickableElement className="addButton" onClick={handleButtonClick}>
        <AddCircle size={6} fill={colors.silverSand} />
      </ClickableElement>
    </div>
  );
};

AddressInput.propTypes = {
  placeholder: PropTypes.string,
  onButtonClick: PropTypes.func,
  className: PropTypes.string,
};

AddressInput.defaultProps = {
  placeholder: '',
  onButtonClick: () => {},
  className: '',
};

export default styled(AddressInput)`
  position: relative;

  .success {
    display: none;
  }

  .inputField {
    color: ${colors.silverSandAlt};

    input {
      padding-right: 1.5rem;
      max-width: calc(100% - 1.75rem);
      box-sizing: content-box;
    }
  }

  .addButton {
    position: absolute;
    top: 0.7rem;
    right: 0;
  }

  .addButton svg {
    fill: #ff9900;
    stroke: #0099ff;
  }
`;
