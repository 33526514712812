import React from 'react';
import PropTypes from 'prop-types';
import Admin from './Admin';
import Client from './Client';
import Store from './Store';

const App = ({ environment, token, className }) => {
  if (!environment || !token) {
    return null;
  }

  let audience = 'client';

  if (window.location.href.indexOf('admin/client_editor_form.php') > -1) {
    audience = 'admin';
  }
  Store.initialize(environment, audience, token);

  if (audience === 'admin') {
    return <Admin className={className} />;
  }

  return <Client className={className} />;
};

App.propTypes = {
  token: PropTypes.string,
  environment: PropTypes.string,
  className: PropTypes.string,
};

App.defaultProps = {
  token: null,
  environment: null,
  className: '',
};

export default App;
