import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { CSSTransition } from 'react-transition-group';

import { Close } from '@bblabs/svgs';
import BodyEnd from './BodyEnd';
import useWindowSize from './useWindowSize';

const durationIn = 350;
const durationOut = 200;
const easeIn = 'cubic-bezier(.65,0,.35,1)';
const easeOut = easeIn;

function FancyModal({ className, show, children, onClose, onClickout }) {
  const size = useWindowSize();
  const controlsRef = useRef(null);
  const overlayRef = useRef(null);

  const handleMouseDown = event => {
    if (overlayRef.current === event.target || controlsRef.current === event.target) {
      onClickout(event);
    }
  };

  const getControlsHeight = () => {
    return size.height - controlsRef.clientHeight;
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      onClose(event);
    }
  };

  document.body.style.overflow = show ? 'hidden' : 'auto';

  return (
    <BodyEnd>
      <div className={className}>
        <CSSTransition
          in={show}
          timeout={Math.max(durationIn, durationOut)}
          classNames="modalTransition"
          unmountOnExit
        >
          <div role="presentation" onMouseDown={handleMouseDown}>
            <div className="modalBack" ref={overlayRef}>
              <div role="dialog" className="modal">
                <div className="modalControls" ref={controlsRef}>
                  <div
                    className="modalClose"
                    onClick={onClose}
                    onKeyPress={handleKeyPress}
                    role="button"
                    tabIndex={0}
                  >
                    <Close size="8" fill="white" />
                  </div>
                </div>
                <div className="modalContent" style={{ height: `${getControlsHeight()}px` }}>
                  {children}
                </div>
              </div>
            </div>
          </div>
        </CSSTransition>
      </div>
    </BodyEnd>
  );
}

FancyModal.propTypes = {
  show: PropTypes.bool,
  className: PropTypes.string,
  onClose: PropTypes.func,
  onClickout: PropTypes.func,
  children: PropTypes.element.isRequired,
};

FancyModal.defaultProps = {
  show: false,
  className: '',
  onClose: () => {},
  onClickout: () => {},
};

export default styled(FancyModal)`
  -webkit-overflow-scrolling: touch;

  * {
    box-sizing: border-box;
  }

  .modalControls {
    height: 53px;
  }

  .modalClose {
    padding-bottom: 8px;
    margin-top: 22px;
    float: right;
  }

  .modalClose:hover {
    cursor: pointer;
  }

  .modalBack {
    background: #00000080;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
  }

  .modalTransition-enter .modalBack,
  .modalTransition-appear .modalBack {
    background: #00000010;
  }

  .modalTransition-enter-active .modalBack,
  .modalTransition-appear-active .modalBack {
    transition: all ${durationIn}ms ${easeIn};
    background: #00000080;
  }

  .modalTransition-exit .modalBack {
    transition: all ${durationOut}ms ${easeOut};
    background: #00000080;
  }

  .modalTransition-exit-active .modalBack {
    background: #00000010;
  }

  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: right;
    overflow: hidden;
    max-height: 100%;
    max-width: 96%;
    min-height: 50%;
  }

  /* This fires as soon as the element enters the dom */
  .modalTransition-enter .modal,
  .modalTransition-appear .modal {
    transform: translate(-50%, -50%) scale(0.25);
  }

  /* This is where we can add the transition*/
  .modalTransition-enter-active .modal,
  .modalTransition-appear-active .modal {
    transition: all ${durationIn}ms ${easeIn};
    transform: translate(-50%, -50%);
  }

  /* This fires as soon as the this.state.showList is false */
  .modalTransition-exit .modal {
    transition: all ${durationOut}ms ${easeOut};
    transform: translate(-50%, -50%);
  }

  /* fires as element leaves the DOM*/
  .modalTransition-exit-active .modal {
    transform: translate(-50%, -50%) scale(0.33);
  }

  .modalContent {
    max-height: calc(100vh - 53px);
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    opacity: 1;
    background: white;
    text-align: initial;
  }
  /* This fires as soon as the element enters the dom */
  .modalTransition-enter .modalContent,
  .modalTransition-appear .modalContent {
    opacity: 0;
  }

  /* This is where we can add the transition*/
  .modalTransition-enter-active .modalContent,
  .modalTransition-appear-active .modalContent {
    transition: all ${durationIn}ms ${easeIn};
    opacity: 1;
  }

  /* This fires as soon as the this.state.showList is false */
  .modalTransition-exit .modalContent {
    transition: all ${durationOut}ms ${easeOut};
    opacity: 1;
  }

  /* fires as element leaves the DOM*/
  .modalTransition-exit-active .modalContent {
    opacity: 0;
  }
`;
