import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import { ClickableElement } from '@bblabs/atoms';
import { colors, heavy } from '@bblabs/styles';
import Store from './Store';
import ResendVerificationButton from './ResendVerificationButton';

const SendingAddressEntry = observer(({ className }) => {
  const oracleTimestampMonths = {
    JAN: '01',
    FEB: '02',
    MAR: '03',
    APR: '04',
    MAY: '05',
    JUN: '06',
    JUL: '07',
    AUG: '08',
    SEP: '09',
    OCT: '10',
    NOV: '11',
    DEC: '12',
  };

  const handleRemoveClick = async address => {
    await Store.remove(address);
    Store.fetchAddresses();
  };

  const getStatus = verified => {
    if (verified === '1') {
      return <div className="verified">Verified</div>;
    }
    return <div className="incomplete">Incomplete</div>;
  };

  const getIsPrimaryOrRemoveOption = address => {
    if (address.is_primary === '1') {
      return <div className="primary">Primary</div>;
    }
    return (
      <ClickableElement className="removeButton" onClick={() => handleRemoveClick(address)}>
        Remove
      </ClickableElement>
    );
  };

  // Once Big Red (Oracle) exists no more, remove Oracle format code. We'll support both
  // old Oracle timestamp and new date format at the same time for now.
  const isOracleDateFormat = dateString => {
    const monthKeys = Object.keys(oracleTimestampMonths);
    if (new RegExp(monthKeys.join('|')).test(dateString)) {
      return true;
    }
    return false;
  };

  // Example input format: 21-SEP-20 03.06.50.688191 PM
  // Output: MM/DD/YY
  const parseOracleTimestamp = dateString => {
    const day = dateString.slice(0, 2);
    const month = oracleTimestampMonths[dateString.slice(3, 6)];
    const year = dateString.slice(7, 9);

    return `${month}/${day}/${year}`;
  };

  // Example input format: 2023-02-16 23:20:58
  // Output: MM/DD/YY
  const parsePostgresDate = dateString => {
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
    });
  };

  // Parse db timestamp
  // Example format: 21-SEP-20 03.06.50.688191 PM (until Oracle is no more)
  // or
  // Example format: 2020-09-21 23:20:58
  const parseVerifiedDate = dateString => {
    const isOracleTimestamp = isOracleDateFormat(dateString);
    if (isOracleTimestamp) {
      return parseOracleTimestamp(dateString);
    }
    return parsePostgresDate(dateString);
  };

  return (
    <ul className={className}>
      {Store.addresses &&
        Store.addresses.map(address => {
          const verifiedStatus = getStatus(address.verified);
          const verifiedDate = address.verification_date
            ? parseVerifiedDate(address.verification_date)
            : '--';

          const verifiedDateOrResendOption =
            verifiedStatus.props.className !== 'incomplete' ? (
              verifiedDate
            ) : (
              <ResendVerificationButton address={address} />
            );

          const primaryAddressOrRemoveOption = getIsPrimaryOrRemoveOption(address);

          return (
            <li key={address.id}>
              <div className="email">{address.email_address}</div>
              <div className="status">{verifiedStatus}</div>
              <div className="verifiedDate">{verifiedDateOrResendOption}</div>
              {primaryAddressOrRemoveOption}
            </li>
          );
        })}
    </ul>
  );
});

SendingAddressEntry.propTypes = {
  className: PropTypes.string,
};

SendingAddressEntry.defaultProps = {
  className: '',
};

export default styled(SendingAddressEntry)`
  padding: 0;
  margin: 0;
  font-size: 1rem;

  li {
    background: rgba(240, 240, 240, 0.43); /* "fog" at 40% opacity */
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    padding: 1rem;
    margin-bottom: 0;
  }

  li > div {
    vertical-align: middle;
    color: ${colors.boulder};
  }

  .email {
    text-align: left;
    flex-basis: 45%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .status {
    flex-basis: 17%;
  }

  .verifiedDate {
    flex-basis: 27%;
  }

  .removeButton,
  .primary {
    flex-basis: 11%;
  }

  .status,
  .verifiedDate,
  .removeButton,
  .primary {
    text-align: center;
    font-size: 0.85em;
  }

  .removeButton {
    color: ${colors.silverSandAlt};
    text-decoration: underline;
  }

  .removeButton:hover {
    color: ${colors.boulder};
  }

  .incomplete {
    ${heavy}
    font-style: italic;
    color: ${colors.almostBlack};
  }

  li:nth-of-type(even) {
    background: ${colors.fog};
  }

  @media (max-width: 730px) {
    .email {
      text-align: center;
      display: block;
    }

    li {
      flex-wrap: wrap;
      padding: 1.5rem;
    }

    li > div:first-of-type {
      width: 100%;
      flex-basis: 100%;
    }

    li > div:not(:first-of-type) {
      flex: 1;
    }

    .email {
      padding-bottom: 0.75rem;
      text-align: left;
    }

    .status {
      text-align: left;
    }

    .removeButton,
    .primary {
      text-align: right;
    }
  }
`;
