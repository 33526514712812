import React from 'react';
import ReactDOM from 'react-dom';
import styled from '@emotion/styled';

const BodyEnd = ({ className, children }) => {
  return ReactDOM.createPortal(<div className={className}>{children}</div>, document.body);
};

export default styled(BodyEnd)`
  display: contents;
`;
