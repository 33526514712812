import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { book } from '@bblabs/styles';
import { observer } from 'mobx-react';
import SendingAddresses from './SendingAddresses';
import AddressInput from './AddressInput';
import Store from './Store';

const AdminModalContents = observer(({ className }) => {
  const handleManuallyVerifyClick = async value => {
    const results = await Store.manuallyVerify(value);
    if (!results.ok) {
      /* eslint no-console: ["warn", { allow: ["error"] }] */
      console.error(await results.text());
    }
    Store.fetchAddresses();
  };

  const handleEmailVerifyClick = async value => {
    const results = await Store.sendVerification(value);
    if (!results.ok) {
      /* eslint no-console: ["warn", { allow: ["error"] }] */
      console.error(await results.text());
    }
    Store.fetchAddresses();
  };

  return (
    <div className={className}>
      <h1>Associated Emails</h1>
      <SendingAddresses />
      <div className="addressInputs">
        <AddressInput
          className="addressInput"
          placeholder="Manually Verify Email Address"
          onButtonClick={handleManuallyVerifyClick}
        />
        <AddressInput
          className="addressInput"
          placeholder="Send Email Verification to Customer"
          onButtonClick={handleEmailVerifyClick}
        />
      </div>
    </div>
  );
});

AdminModalContents.propTypes = {
  className: PropTypes.string,
};

AdminModalContents.defaultProps = {
  className: '',
};

export default styled(AdminModalContents)`
  ${book()}
  width: 58rem;
  max-width: 100%;
  padding: 3.25rem;

  .addressInputs {
    padding-top: 2em;
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
  }

  .addressInput {
    flex-basis: 50%;
  }

  .addressInput:first-of-type {
    margin-right: 2em;
  }

  .addressInput:last-of-type {
    margin-left: 2em;
  }

  h1 {
    margin-top: 0;
  }

  @media (max-width: 730px) {
    .addressInputs {
      display: block;
    }

    .addressInput:first-of-type {
      margin-right: inherit;
    }

    .addressInput:last-of-type {
      margin-left: inherit;
    }
  }

  @media (max-width: 800px) {
    padding: 1rem;
  }
`;
